import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-3" style={{ width: 350 }}>
            <img src={require("assets/images/icon-diskominfo.png")} alt="" />
          </div>
          <div className="col-12 col-lg-3 mr-5" style={{ width: 250 }}>
            <h6 className="mt-2">Alamat</h6>
            <p className="brand-tagline">
              Jl. Kapten Maulana Lubis No.2, Petisah Tengah, Kec. Medan Petisah,
              Kota Medan, Sumatera Utara 20231
            </p>
          </div>
          <div className="col-lg-3">
            <h6 className="mt-2">Mitra</h6>
            <img
              src={require("assets/images/metromatika.png")}
              alt=""
              style={{ width: 150, height: 70 }}
            />
          </div>
          <div className="col-12 col-lg-2">
            <h6 className="mt-2">Kontak Kami</h6>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div>Telepone: (061) 4512412</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center copyrights">
            &copy; 2022 Dinas Komunikasi dan Informatika Kota Medan
          </div>
        </div>
      </div>
    </footer>
  );
}
