import React, { useState } from "react";
import ButtonImport from "../components/ButtonImport/ButtonImport";
import FormChart from "../components/Form/FormChart";
import Header from "../components/header/Header";
import ImportIcon from "@mui/icons-material/ExitToApp";
import Menubar from "../components/Menubar/Menubar";
import PolarAreaCharts from "../components/Chart/PolarAreaCharts";
import PieChart from "../components/Chart/PieChart";
import RadarCharts from "../components/Chart/RadarCharts";
import DoughnutChart from "../components/Chart/DoughnutChart";
import BarChart from "../components/Chart/BarVertikalChart";
import LineChart from "../components/Chart/LineChart";
const Politic = () => {
  const [viewForm, setViewForm] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#EDEDEB",
        height: "auto",
        minHeight: "100vh"
      }}>
      <Header />
      <Menubar />
      {viewForm ? (
        <FormChart />
      ) : (
        <div className="container p-0" style={{ minHeight: "70vh" }}>
          <div className="bg-white my-3 p-2">
            <h3 style={{ color: "#25883B" }}>Politik</h3>
          </div>
          <div className="bg-white">
            <div
              style={{
                padding: "10px"
              }}>
              <ButtonImport
                title={"Import Data Grafik"}
                icon={<ImportIcon />}
                action={() => setViewForm(true)}
              />
              <hr />
              {/* form input chart */}
              <div className="d-flex flex-row col-12">
                <div className="col-12">
                  <LineChart />
                </div>
              </div>
              <div className="col-12">
                <BarChart />
              </div>
              <div className="d-flex flex-row col-12">
                <div className="col-6">
                  <DoughnutChart />
                </div>
                <div className="col-6">
                  <RadarCharts />
                </div>
              </div>
              <div className="d-flex flex-row col-12">
                <div className="col-6">
                  <PieChart />
                </div>
                <div className="col-6">
                  <PolarAreaCharts />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Politic;
