import React from 'react';


const Button = ({title,icon}) => {
  return (
    <button className='btn btn-warning text-light'>
        {title} {icon}
    </button>
  )
}

export default Button