import React from "react";
import Header from "../components/header/Header";
import Menubar from "../components/Menubar/Menubar";
const RequestAccount = () => {
  return (
    <div style={{ backgroundColor: "#EDEDEB", minHeight: "100vh" }}>
      <Header />
      <Menubar />
      <div className="container bg-white my-3 px-5 py-2">
        <h4 style={{ color: "#25883B" }}>Daftar Akun User</h4>
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              DataTables Example
            </h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div
                id="dataTable_wrapper"
                class="dataTables_wrapper dt-bootstrap4">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length" id="dataTable_length">
                      <label>
                        Show{" "}
                        <select
                          name="dataTable_length"
                          aria-controls="dataTable"
                          class="custom-select custom-select-sm form-control form-control-sm">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="dataTable_filter" class="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder=""
                          aria-controls="dataTable"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <table
                      class="table table-bordered dataTable"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                      role="grid"
                      aria-describedby="dataTable_info"
                      style={{ width: "100%" }}>
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Name: activate to sort column ascending"
                            style={{ width: "84px" }}>
                            ID
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Position: activate to sort column ascending"
                            style={{ width: "117px" }}>
                            Nama Dinas
                          </th>
                          <th
                            class="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Age: activate to sort column ascending"
                            style={{ width: "31px" }}>
                            Sub Domain
                          </th>
                          <th
                            class="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Start date: activate to sort column descending"
                            aria-sort="ascending"
                            style={{ width: "72px" }}>
                            Nama Situs
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th rowspan="1" colspan="1">
                            ID
                          </th>
                          <th rowspan="1" colspan="1">
                            Nama Dinas
                          </th>
                          <th rowspan="1" colspan="1">
                            Sub Domain
                          </th>
                          <th rowspan="1" colspan="1">
                            Nama Situs
                          </th>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr class="odd">
                          <td class="">D103</td>
                          <td>Dinas Pariwisata</td>
                          <td>https://dinaspariwisata.pemkomedan.go.id/</td>
                          <td>Sistem Informasi Dinas Pariwisata</td>
                        </tr>
                        <tr class="even">
                          <td class="">D103</td>
                          <td>Dinas Pariwisata</td>
                          <td>https://dinaspariwisata.pemkomedan.go.id/</td>
                          <td>Sistem Informasi Dinas Pariwisata</td>
                        </tr>
                        <tr class="odd">
                          <td class="">D103</td>
                          <td>Dinas Pariwisata</td>
                          <td>https://dinaspariwisata.pemkomedan.go.id/</td>
                          <td>Sistem Informasi Dinas Pariwisata</td>
                        </tr>
                        <tr class="even">
                          <td class="">D103</td>
                          <td>Dinas Pariwisata</td>
                          <td>https://dinaspariwisata.pemkomedan.go.id/</td>
                          <td>Sistem Informasi Dinas Pariwisata</td>
                        </tr>
                        <tr class="odd">
                          <td class="">D103</td>
                          <td>Dinas Pariwisata</td>
                          <td>https://dinaspariwisata.pemkomedan.go.id/</td>
                          <td>Sistem Informasi Dinas Pariwisata</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div
                      class="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite">
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate">
                      <ul class="pagination">
                        <li
                          class="paginate_button page-item previous disabled"
                          id="dataTable_previous">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link">
                            Previous
                          </a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link">
                            1
                          </a>
                        </li>
                        <li class="paginate_button page-item ">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="2"
                            tabindex="0"
                            class="page-link">
                            2
                          </a>
                        </li>
                        <li class="paginate_button page-item ">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="3"
                            tabindex="0"
                            class="page-link">
                            3
                          </a>
                        </li>
                        <li class="paginate_button page-item ">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="4"
                            tabindex="0"
                            class="page-link">
                            4
                          </a>
                        </li>
                        <li class="paginate_button page-item ">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="5"
                            tabindex="0"
                            class="page-link">
                            5
                          </a>
                        </li>
                        <li class="paginate_button page-item ">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="6"
                            tabindex="0"
                            class="page-link">
                            6
                          </a>
                        </li>
                        <li
                          class="paginate_button page-item next"
                          id="dataTable_next">
                          <a
                            href="/#"
                            aria-controls="dataTable"
                            data-dt-idx="7"
                            tabindex="0"
                            class="page-link">
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestAccount;
