import React from "react";
import { Button } from "react-bootstrap";

const ButtonImport = ({ title, icon, action }) => {
  return (
    <Button
      className="btn-import"
      onClick={action}
      style={{
        backgroundColor: "#25883B",
        borderRadius: 8,
        border: 0,
        padding: "10px 25px"
      }}>
      {icon}
      {title}
    </Button>
  );
};

export default ButtonImport;
