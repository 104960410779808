import Header from "components/header/Header";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import WaliKota from "assets/images/walkot.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./landingPage.css";
import { Form, InputGroup } from "react-bootstrap";
import Footer from "components/footer/Footer";
import LineChart from "components/Chart/LineChart";

export default function LandingPage() {
  return (
    <>
      <Header />
      {/* <div className="container-landingpage"> */}
      <div className="banner col d-flex align-items-center">
        <div className="container d-flex col flex-row align-items-center justify-content-center">
          <div className="col-lg-6 col-6 pt-5 d-flex flex-column justify-content-left">
            <h1
              className="text-light m-0"
              style={{ fontWeight: 700, fontSize: 56, marginLeft: 50 }}>
              Selamat Datang
              <p className="text-banner">di Aplikasi Satu Data Kota Medan</p>
            </h1>
            <InputGroup size="lg" className="w-75 mt-5">
              <InputGroup.Text className="bg-white border-0">
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                className="border-0 py-3"
                placeholder="Cari Dataset disini"
              />
            </InputGroup>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center p-0">
            <img src={WaliKota} alt="" style={{ margin: 0 }} />
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="body-container py-5">
        <p className="text-category">Telusuri Berdasarkan Sektor Dataset</p>
        <div className="category-set justify-content-around">
          <div className="category-icon-set col-2">
            <img
              src={require("assets/images/icons/icon_healthcare.png")}
              alt=""
              width={30}
              height={30}
            />
            <p style={{ fontSize: "20px", color: "#25883B", margin: 0 }}>
              Kesehatan
            </p>
          </div>
          <div className="category-icon-set col-2">
            <img
              src={require("assets/images/icons/icon-money.png")}
              alt=""
              width={30}
              height={30}
            />
            <p style={{ fontSize: "20px", color: "#25883B", margin: 0 }}>
              Ekonomi
            </p>
          </div>
          <div className="category-icon-set col-2">
            <img
              src={require("assets/images/icons/icon-building.png")}
              alt=""
              width={30}
              height={30}
            />
            <p style={{ fontSize: "20px", color: "#25883B", margin: 0 }}>
              Politik
            </p>
          </div>
          <div className="category-icon-set col-2">
            <img
              src={require("assets/images/icons/icon-school.png")}
              alt=""
              width={30}
              height={30}
            />
            <p style={{ fontSize: "20px", color: "#25883B", margin: 0 }}>
              Pendidikan
            </p>
          </div>
          <div className="category-icon-set col-2">
            <img
              src={require("assets/images/icons/icon-heart.png")}
              alt=""
              width={30}
              height={30}
            />
            <p style={{ fontSize: "20px", color: "#25883B", margin: 0 }}>
              Keejahteraan
            </p>
          </div>
        </div>
        <p className="text-category">Indikator Kinerja Daerah Kota Medan</p>
        <div className="container-grid justify-content-around">
          <div className="item-card col-4">
            <div className="card p-1 align-items-center">
              <img src={require("assets/images/icons/category-1.png")} alt="" />
              <p
                className="my-0 px-3"
                style={{
                  borderBottom: "1.5px solid green",
                  fontWeight: 500,
                  color: "#125621"
                }}>
                Indeks Kinerja Sistem Infrastruktur
              </p>
              <p className="my-0">Tahun 2022</p>
              <p
                className="my-0"
                style={{ fontSize: "24px", fontWeight: 700, color: "#125621" }}>
                80,15
              </p>
              <p
                className="my-0 text-sm"
                style={{ fontSize: "12px", color: "#125621" }}>
                Data Tahun 2021 | 80,00
              </p>
            </div>
          </div>
          <div className="item-card col-4 px-2">
            <div className="card p-1 align-items-center">
              <img src={require("assets/images/icons/category-2.png")} alt="" />
              <p
                className="my-0 px-3"
                style={{
                  borderBottom: "1.5px solid green",
                  fontWeight: 500,
                  color: "#125621"
                }}>
                Indeks Kinerja Sistem Infrastruktur
              </p>
              <p className="my-0">Tahun 2022</p>
              <p
                className="my-0"
                style={{ fontSize: "24px", fontWeight: 700, color: "#125621" }}>
                80,15
              </p>
              <p
                className="my-0 text sm"
                style={{ fontSize: "12px", color: "#125621" }}>
                Data Tahun 2021 | 80,00
              </p>
            </div>
          </div>
          <div className="item-card col-4">
            <div className="card p-1 align-items-center">
              <img src={require("assets/images/icons/category-3.png")} alt="" />
              <p
                className="my-0 px-3"
                style={{
                  borderBottom: "1.5px solid green",
                  fontWeight: 500,
                  color: "#125621"
                }}>
                Indeks Kinerja Sistem Infrastruktur
              </p>
              <p className="my-0">Tahun 2022</p>
              <p
                className="my-0"
                style={{ fontSize: "24px", fontWeight: 700, color: "#125621" }}>
                80,15
              </p>
              <p
                className="my-0 text sm"
                style={{ fontSize: "12px", color: "#125621" }}>
                Data Tahun 2021 | 80,00
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around py-4">
          <div className="dinas-list rounded col-6" style={{ borderRadius: 8 }}>
            <div className="d-flex flex-row py-2 bg-warning rounded py-3">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinas.png")}
                  alt="dinas"
                />
              </div>
              <h5 className="text-light my-auto" style={{ width: "80%" }}>
                Top Dinas
              </h5>
            </div>
            <div className="d-flex flex-row py-2 bg-white">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt="dinas"
                />
              </div>
              <p className="font-weight-bold my-auto" style={{ width: "80%" }}>
                Dinas Komunikasi dan Informatika Kota Medan
              </p>
            </div>
            <div className="d-flex flex-row py-2 bg-white">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt="dinas"
                />
              </div>
              <p className="font-weight-bold my-auto" style={{ width: "80%" }}>
                Dinas Kesehatan Kota Medan
              </p>
            </div>
            <div className="d-flex flex-row py-2 bg-white">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt="dinas"
                />
              </div>
              <p className="font-weight-bold my-auto" style={{ width: "80%" }}>
                Dinas Tata Ruang dan Bangunan Kota Medan
              </p>
            </div>
            <div className="d-flex flex-row py-2 bg-white">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt="dinas"
                />
              </div>
              <p className="font-weight-bold my-auto" style={{ width: "80%" }}>
                Dinas Pendapatan Daerah Kota Medan
              </p>
            </div>
            <div className="d-flex flex-row py-2 bg-white">
              <div
                style={{
                  width: "15%"
                }}>
                <img
                  className="mx-auto d-block"
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt="dinas"
                />
              </div>
              <p className="font-weight-bold my-auto" style={{ width: "80%" }}>
                Dinas Kependudukan dan Catatan Sipil Kota Medan
              </p>
            </div>
          </div>
          <div
            className="col-6 mx-2 d-flex align-items-end justify-content-end"
            style={{
              borderRadius: 4
            }}>
            <img
              src={require("assets/images/maps.png")}
              alt="maps"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <div className="article-container">
          <h5
            className="font-weight-bold"
            style={{ color: "#125621", fontWeight: 700 }}>
            Terbaru
          </h5>
          <div>
            <div className="d-flex flex-row justify-content-between px-2 my-3">
              <div className="d-flex flex-row" style={{ width: "65%" }}>
                <div className="bg-white">
                  <img src={require("assets/images/article-img.png")} alt="" />
                </div>
                <div className="bg-white d-flex flex-column px-2 py-2">
                  <p style={{ fontSize: 16, fontWeight: 500 }}>
                    Anjuran kepada masyarakat untuk menaati protokol kesehatan
                    demi mencegah penularan penyakit Covid-19
                  </p>
                  <div className="d-flex flex-row" style={{ fontSize: 14 }}>
                    <img
                      className="my-0"
                      style={{ width: 20, height: 20 }}
                      src={require("assets/images/icons/dinasbordered.png")}
                      alt=""
                    />
                    <p className="my-0">Dinas Kesehatan Kota Medan</p>
                  </div>
                  <div
                    className="d-flex flex-row my-auto"
                    style={{ fontSize: 14 }}>
                    <AccessTimeIcon />
                    <p className="my-auto">22 Oktober 2022 - 13:00</p>
                  </div>
                  <p style={{ fontSize: 14 }}>
                    Lambatnya penurunan kasus Covid-19 yang ada di Kota Medan
                    membuat masyarakat dihimbau agar lebih berhati-hati lagi dan
                    selalu menaati protokol kesehatan yang telah di himbau
                    sebelumnya oleh pemerintah maupun petugas kesehatan
                    lainnya....
                  </p>
                </div>
              </div>
              <div className="side-article">
                <div className="d-flex flex-column mb-3">
                  <h5
                    className="font-weight-bold text-light"
                    style={{ fontSize: 14 }}>
                    Bertemu Bobby Nasution, Eddy Berutu Siapkan Lahan 300
                    Hektarsuplai kebutuhan Pokok....
                  </h5>
                  <p className="text-light" style={{ fontSize: 12 }}>
                    Sabtu, 22 Oktober 2022 | 18:08 WIB
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between px-2 my-3">
              <div className="d-flex flex-row" style={{ width: "65%" }}>
                <div className="bg-white">
                  <img src={require("assets/images/article-img.png")} alt="" />
                </div>
                <div className="bg-white d-flex flex-column px-2 py-2">
                  <p style={{ fontSize: 16, fontWeight: 500 }}>
                    Anjuran kepada masyarakat untuk menaati protokol kesehatan
                    demi mencegah penularan penyakit Covid-19
                  </p>
                  <div className="d-flex flex-row" style={{ fontSize: 14 }}>
                    <img
                      className="my-0"
                      style={{ width: 20, height: 20 }}
                      src={require("assets/images/icons/dinasbordered.png")}
                      alt=""
                    />
                    <p className="my-0">Dinas Kesehatan Kota Medan</p>
                  </div>
                  <div
                    className="d-flex flex-row my-auto"
                    style={{ fontSize: 14 }}>
                    <AccessTimeIcon />
                    <p className="my-auto">22 Oktober 2022 - 13:00</p>
                  </div>
                  <p style={{ fontSize: 14 }}>
                    Lambatnya penurunan kasus Covid-19 yang ada di Kota Medan
                    membuat masyarakat dihimbau agar lebih berhati-hati lagi dan
                    selalu menaati protokol kesehatan yang telah di himbau
                    sebelumnya oleh pemerintah maupun petugas kesehatan
                    lainnya....
                  </p>
                </div>
              </div>
              <div className="side-article">
                <div className="d-flex flex-column mb-3">
                  <h5
                    className="font-weight-bold text-light"
                    style={{ fontSize: 14 }}>
                    Bertemu Bobby Nasution, Eddy Berutu Siapkan Lahan 300
                    Hektarsuplai kebutuhan Pokok....
                  </h5>
                  <p className="text-light" style={{ fontSize: 12 }}>
                    Sabtu, 22 Oktober 2022 | 18:08 WIB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5
          className="font-weight-bold py-3"
          style={{ color: "#125621", fontWeight: 700 }}>
          Sekilas tentang Medan
        </h5>
        <div className="row d-flex flex-row justify-content-between px-2">
          <div className="d-flex flex-column" style={{ width: "30%" }}>
            <div className="p-3 bg-white border-bottom">
              <div className="d-flex flex-row mb-3" style={{ fontSize: 14 }}>
                <img
                  className="my-auto"
                  style={{ width: 20, height: 20 }}
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt=""
                />
                <p className="my-auto mx-3">Fasilitas Kesehatan</p>
              </div>
              <div className="d-flex flex-row justify-content-around">
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-around">
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Rumah Sakit
                  </p>
                </div>
              </div>
            </div>
            <div className="align-items-center d-flex bg-white">
              <p className="text-green mx-auto my-3">
                Sumber: Dinkes Kota Medan
              </p>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ width: "30%" }}>
            <div className="p-3 bg-white border-bottom">
              <div className="d-flex flex-row mb-3" style={{ fontSize: 14 }}>
                <img
                  className="my-auto"
                  style={{ width: 20, height: 20 }}
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt=""
                />
                <p className="my-auto mx-3">Jumlah Sekolah</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Sekolah Dasar
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Sekolah Menengah Pertama
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">130</p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Sekolah Menengah Atas
                  </p>
                </div>
              </div>
            </div>
            <div className="align-items-center d-flex bg-white">
              <p className="text-green mx-auto my-3">
                Sumber: Disdik Kota Medan
              </p>
            </div>
          </div>
          <div className="d-flex flex-column" style={{ width: "30%" }}>
            <div className="p-3 bg-white border-bottom">
              <div className="d-flex flex-row mb-3" style={{ fontSize: 14 }}>
                <img
                  className="my-auto"
                  style={{ width: 20, height: 20 }}
                  src={require("assets/images/icons/dinasbordered.png")}
                  alt=""
                />
                <p className="my-auto mx-3">Jumlah Sekolah</p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">
                    185.048.495
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Total Penduduk
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-around">
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">
                    92.524.248
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Laki-laki
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="m-0 p-0 text-green font-weight-bold">
                    92.524.246
                  </p>
                  <p className="m-0 p-0" style={{ fontSize: 12 }}>
                    Perempuan
                  </p>
                </div>
              </div>
            </div>
            <div className="align-items-center d-flex bg-white">
              <p className="text-green mx-auto my-3">
                Sumber: Disdukcapil Kota Medan
              </p>
            </div>
          </div>
        </div>
        <div className="row d-flex flex-column align-items-center col-12 bg-white my-3 p-3 mx-2">
          <div
            className="col d-flex justify-content-center rounded py-3"
            style={{ backgroundColor: "rgba(37, 136, 59, 0.2)" }}>
            <h5 className="text-green font-weight-bold">
              Medan Lawan Covid-19
            </h5>
          </div>
          <h5 className="text-center">DATA COVID-19 KOTA MEDAN</h5>
          <LineChart />
        </div>
      </div>
      <Footer />
    </>
  );
}
