import LineChart from "./components/Chart/LineChart";
import React from "react";
import Card from "./components/card/Card";
import Header from "./components/header/Header";
import Menubar from "./components/Menubar/Menubar";

import PieChart from "./components/Chart/PieChart";
import DoughnutChart from "./components/Chart/DoughnutChart";
import RadarCharts from "./components/Chart/RadarCharts";
import PolarAreaCharts from "./components/Chart/PolarAreaCharts";
import BarHorizontal from "./components/Chart/BarHorizontal";
import BarVertikalChart from "./components/Chart/BarVertikalChart";

const Dashboard = () => {
  return (
    <div style={{ backgroundColor: "#EDEDEB" }}>
      <Header />
      <Menubar />
      <Card />
      <div className="container">
        <div className="col">
          <div className="col-12">
            <LineChart />
          </div>
        </div>
        <div className="col-12">
          <BarHorizontal />
        </div>
        <div className="d-flex flex-row justify-content-between col-12">
          <div className="col-6">
            <DoughnutChart />
          </div>
          <div className="col-6">
            <RadarCharts />
          </div>
        </div>
        <div className="col-12">
          <BarVertikalChart />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="col-6">
            <DoughnutChart />
          </div>
          <div className="col-6">
            <RadarCharts />
          </div>
        </div>
        <div className="d-flex flex-row col-12">
          <div className="col-6">
            <PieChart />
          </div>
          <div className="col-6">
            <PolarAreaCharts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
