import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarHorizontal = () => {
  const labels = [
    "01-22",
    "02-22",
    "03-22",
    "04-22",
    "05-22",
    "06-22",
    "07-22",
    "08-22",
    "09-22",
    "10-22",
    "11-22",
    "12-22"
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Bawang Merah",
        data: [
          0, 12000, 25000, 30000, 20000, 21000, 40000, 45000, 80000, 32000,
          100000, 10000
        ],
        borderColor: "#DA251D",
        backgroundColor: "#DA251D",
        tension: 0.5
      },
      {
        label: "Bawang Putih",
        data: [
          10000, 15000, 20000, 35000, 20000, 24000, 60000, 55000, 85000, 70000,
          90000, 20000
        ],
        borderColor: "#18A0FB",
        backgroundColor: "#18A0FB",
        tension: 0.5
      },
      {
        label: "Daging Sapi",
        data: [
          100000, 24000, 35000, 30000, 70000, 81000, 90000, 45000, 55000, 32000,
          10000, 100000
        ],
        borderColor: "#3EC04B",
        backgroundColor: "#3EC04B",
        tension: 0.5
      }
    ]
  };
  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom"
      },

      title: {
        display: true,
        text: "Update Data Terakhir : Selasa, 25 Oktober 2022, Jam: 15 : 56"
        // font: { size: 25 }
      }
    }
  };
  return (
    <div className="row" style={{ backgroundColor: "#fff" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarHorizontal;
