import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DoughnutChart = () => {
  const labels = ["SD", "SMP", "SMA"];

  const data = {
    labels,
    datasets: [
      {
        label: "Bawang Merah",
        data: [4000, 12000, 25000],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)"
        ],
        backgroundColor: ["red", "green", "blue"],
        tension: 0.5
      }
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom"
      },

      title: {
        display: true,
        text: "Update Data Terakhirr : Selasa, 25 Oktober 2022, Jam: 15 : 56"
        // font: { size: 25 }
      }
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#fff"
      }}>
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default DoughnutChart;
