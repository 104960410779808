import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const PieChart = () => {
  const data = {
    labels: ["MFA", "NON-MFA"],
    datasets: [
      {
        data: [5667, 223829],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"]
      }
    ]
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom"
      },

      title: {
        display: true,
        text: "Update Data Terakhir : Selasa, 25 Oktober 2022, Jam: 15 : 56"
        // font: { size: 25 }
      }
    }
  };
  return (
    <div className="row" style={{ backgroundColor: "#fff" }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
