import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Radar } from 'react-chartjs-2';
  
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  
  export const data = {
    labels: ['Thing 1', 'Thing 2', 'Thing 3', 'Thing 4', 'Thing 5', 'Thing 6'],
    datasets: [
      {
        label: '# of Votes',
        data: [2, 9, 3, 5, 2, 3],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: '# of Votes',
        data: [19, 5, 3, 1, 4, 7],
        backgroundColor: 'rgba(255, 99, 100, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

const RadarCharts = () => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "bottom"
          },
    
          title: {
            display: true,
            text: "Jumlah Muride"
            // font: { size: 25 }
          }
        }
      };
  return (
    <div  style={{
        backgroundColor:"#fff"
    }}>
        <Radar options={options} data={data} />
    </div>
  )
}

export default RadarCharts