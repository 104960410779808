import React, { useState } from "react";
import { Form, Modal, Tab, Tabs } from "react-bootstrap";
import ButtonImport from "../ButtonImport/ButtonImport";

import Button from "react-bootstrap/Button";
import LineChart from "../Chart/LineChart";
import PieChart from "../Chart/PieChart";
import DoughnutChart from "../Chart/DoughnutChart";
import BarVertikalChart from "../Chart/BarVertikalChart";
import BarHorizontal from "../Chart/BarHorizontal";

const FormChart = () => {
  const [apiForm, setApiForm] = useState(false);

  function ModalPreview(props) {
    return (
      <Modal {...props} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-around">
            <div className="col-6">{props.chart}</div>
            <div className="d-flex flex-column col-4">
              <div className="my-2">
                <p className="m-0">Sumbu X:</p>
                <Form.Select size="md">
                  <option>Small select</option>
                </Form.Select>
              </div>
              <div className="my-2">
                <p className="m-0">Label Sumbu x:</p>
                <Form.Select size="md">
                  <option>Small select</option>
                </Form.Select>
              </div>
              <div className="my-2">
                <p className="m-0">Sumbu Y:</p>
                <Form.Select size="md">
                  <option>Small select</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="success" onClick={props.onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalChart(props) {
    return (
      <Modal {...props} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-center">
            {props.image}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="success" onClick={props.onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = useState(false);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const options = [
    { value: "", text: "Pilih Jenis Chart" },
    { value: "BarV", text: "Vertikal Bar Chart" },
    { value: "BarH", text: "Horizontal Bar Chart" },
    { value: "Line", text: "Line Chart" },
    { value: "Pie", text: "Pie Chart" },
    { value: "Doughnut", text: "Doughnut Chart" }
  ];
  const [selected, setSelected] = useState("");
  // const [selectedPreview, setSelectedPreview] = useState("");

  const handleChange = (event) => {
    setModalShow(true);
    setSelected(event.target.value);
    // console.log(event.target.value);
  };
  const handleModal = (event) => {
    // setSelectedPreview(event.target.value);
    setPreviewModalShow(true);
    setModalShow(false);
  };
  return (
    <div className="container bg-white mt-2 py-4">
      <h4 className="p-3">Tambah Grafik</h4>
      <Tabs defaultActiveKey="update" transition={true} className="mb-3">
        <Tab eventKey="update" className="bg-white px-3" title="Update Grafik">
          <form>
            <table className="table">
              <tr>
                <td>Pilih Grafik Yang Akan Diupdate</td>
                <td>
                  {" "}
                  <Form.Select className="p-2">
                    <option>Eksalasi Harga Pangan Di Setiap Komoditas</option>
                    <option value="1">Sosial</option>
                    <option value="2">Kesehatan</option>
                    <option value="3">Politik</option>
                  </Form.Select>
                </td>
              </tr>
              <tr>
                <td>Pilih Jenis Import</td>
                <td className="d-flex align-items-center">
                  <div key={`inline-radio`}>
                    <Form.Check
                      inline
                      label="Upload Data"
                      name="group1"
                      onClick={() => setApiForm(false)}
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      inline
                      label="API"
                      name="group1"
                      type="radio"
                      onClick={() => setApiForm(true)}
                      id={`inline-radio-2`}
                    />
                  </div>
                </td>
              </tr>
              {apiForm ? (
                <tr>
                  <td>EndPoint URL/Token</td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Masukkan Endpoint Api/Token"
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>Upload File</td>
                  <td>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control type="file" />
                    </Form.Group>
                  </td>
                </tr>
              )}
              <tr>
                <td>Pilih Filter Grafik</td>
                <td>
                  <div key={`inline-checkbox`}>
                    <Form.Check
                      inline
                      disabled
                      label="Kecamatan"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-1`}
                    />
                    <Form.Check
                      inline
                      label="Pasar"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-2`}
                    />
                    <Form.Check
                      inline
                      label="Harga"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-2`}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Pilih Jenis Grafik</td>
                <td>
                  <Form.Select
                    value={selected}
                    onChange={handleChange}
                    className="p-2">
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </td>
              </tr>
            </table>
            <div className="d-flex justify-content-end">
              <ButtonImport title={"Submit"} />
            </div>
          </form>
        </Tab>
        <Tab eventKey="tambah" className="bg-white px-3" title="Tambah Grafik">
          <form>
            <table className="table">
              <tr>
                <td>Judul Grafik</td>
                <td>
                  {" "}
                  <Form.Control
                    type="email"
                    placeholder="Masukkan judul grafik"
                  />
                </td>
              </tr>
              <tr>
                <td>Pilih Jenis Import</td>
                <td className="d-flex align-items-center">
                  <div key={`inline-radio`}>
                    <Form.Check
                      inline
                      label="Upload Data"
                      name="group1"
                      onClick={() => setApiForm(false)}
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      inline
                      label="API"
                      name="group1"
                      type="radio"
                      onClick={() => setApiForm(true)}
                      id={`inline-radio-2`}
                    />
                  </div>
                </td>
              </tr>
              {apiForm ? (
                <tr>
                  <td>EndPoint URL/Token</td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Masukkan Endpoint Api/Token"
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>Upload File</td>
                  <td>
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Control type="file" />
                    </Form.Group>
                  </td>
                </tr>
              )}
              <tr>
                <td>Pilih Filter Grafik</td>
                <td>
                  <div key={`inline-checkbox`}>
                    <Form.Check
                      inline
                      disabled
                      label="Kecamatan"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-1`}
                    />
                    <Form.Check
                      inline
                      label="Pasar"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-2`}
                    />
                    <Form.Check
                      inline
                      label="Harga"
                      name="group1"
                      type="checkbox"
                      id={`inline-checkbox-2`}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Pilih Jenis Grafik</td>
                <td>
                  <Form.Select
                    value={selected}
                    onChange={handleChange}
                    className="p-2">
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                </td>
              </tr>
            </table>
            <div className="d-flex justify-content-end">
              <ButtonImport title={"Submit"} />
            </div>
          </form>
        </Tab>
      </Tabs>
      {selected === "BarV" ? (
        <ModalChart
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Vertikal Bar Chart"}
          onSubmit={handleModal}
          image={
            <img
              src={require("../../../../assets/images/sample-barV.png")}
              alt=""
            />
          }
        />
      ) : selected === "Pie" ? (
        <ModalChart
          show={modalShow}
          onSubmit={handleModal}
          onHide={() => setModalShow(false)}
          title={"Pie Chart"}
          image={
            <img
              src={require("../../../../assets/images/sample-pie.png")}
              alt=""
            />
          }
        />
      ) : selected === "Doughnut" ? (
        <ModalChart
          show={modalShow}
          onSubmit={handleModal}
          onHide={() => setModalShow(false)}
          title={"Doughnut Chart"}
          image={
            <img
              src={require("../../../../assets/images/sample-doughnut.png")}
              alt=""
            />
          }
        />
      ) : selected === "Line" ? (
        <ModalChart
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSubmit={handleModal}
          title={"Line Chart"}
          image={
            <img
              src={require("../../../../assets/images/sample-line.png")}
              alt=""
            />
          }
        />
      ) : selected === "BarH" ? (
        <ModalChart
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSubmit={handleModal}
          title={"Horizontal Bar Chart"}
          image={
            <img
              src={require("../../../../assets/images/sample-barH.png")}
              alt=""
            />
          }
        />
      ) : (
        ""
      )}
      {selected === "BarV" ? (
        <ModalPreview
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          title={"Eksalasi Harga Pangan Tiap Komoditas"}
          chart={<BarVertikalChart />}
        />
      ) : selected === "Line" ? (
        <ModalPreview
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          chart={<LineChart />}
          title={"Eksalasi Harga Pangan Tiap Komoditas"}
        />
      ) : selected === "Pie" ? (
        <ModalPreview
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          chart={<PieChart />}
          title={"Eksalasi Harga Pangan Tiap Komoditas"}
        />
      ) : selected === "BarH" ? (
        <ModalPreview
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          chart={<BarHorizontal />}
          title={"Eksalasi Harga Pangan Tiap Komoditas"}
        />
      ) : selected === "Doughnut" ? (
        <ModalPreview
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          chart={<DoughnutChart />}
          title={"Eksalasi Harga Pangan Tiap Komoditas"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default FormChart;
