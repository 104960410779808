import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ButtonImport from "../components/ButtonImport/ButtonImport";
import ImportIcon from "@mui/icons-material/ExitToApp";
import Header from "../components/header/Header";
import Menubar from "../components/Menubar/Menubar";
import FormChart from "../components/Form/FormChart";
import PolarAreaCharts from "../components/Chart/PolarAreaCharts";
import PieChart from "../components/Chart/PieChart";
import RadarCharts from "../components/Chart/RadarCharts";
import DoughnutChart from "../components/Chart/DoughnutChart";
import BarChart from "../components/Chart/BarVertikalChart";
import LineChart from "../components/Chart/LineChart";

const Economy = () => {
  const [viewForm, setViewForm] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#EDEDEB",
        height: "auto",
        minHeight: "100vh"
      }}>
      <Header />
      <Menubar />
      {viewForm ? (
        <FormChart />
      ) : (
        <div className="container p-0" style={{ minHeight: "70vh" }}>
          <div className="bg-white my-3 p-2">
            <h3 style={{ color: "#25883B" }}>Ekonomi</h3>
          </div>
          <div className="bg-white">
            <Tabs defaultActiveKey="pangan" transition={true} className="mb-3">
              <Tab eventKey="pangan" className="bg-white" title="Pangan">
                <div
                  style={{
                    padding: "10px"
                  }}>
                  <ButtonImport
                    title={"Import Data Grafik"}
                    icon={<ImportIcon />}
                    action={() => setViewForm(true)}
                  />
                  <hr />
                  {/* form input chart */}
                  <div className="d-flex flex-row col-12">
                    <div className="col-12">
                      <LineChart />
                    </div>
                  </div>
                  <div className="col-12">
                    <BarChart />
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <DoughnutChart />
                    </div>
                    <div className="col-6">
                      <RadarCharts />
                    </div>
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <PieChart />
                    </div>
                    <div className="col-6">
                      <PolarAreaCharts />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="pendapatan"
                className="bg-white"
                title="Pendapatan">
                <div
                  style={{
                    padding: "10px"
                  }}>
                  <ButtonImport
                    title={"Import Data Grafik"}
                    icon={<ImportIcon />}
                    action={() => setViewForm(true)}
                  />
                  <hr />
                  {/* form input chart */}
                  <div className="d-flex flex-row col-12">
                    <div className="col-12">
                      <LineChart />
                    </div>
                  </div>
                  <div className="col-12">
                    <BarChart />
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <DoughnutChart />
                    </div>
                    <div className="col-6">
                      <RadarCharts />
                    </div>
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <PieChart />
                    </div>
                    <div className="col-6">
                      <PolarAreaCharts />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="ecommerce" className="bg-white" title="E-commerce">
                <div
                  style={{
                    padding: "10px"
                  }}>
                  <ButtonImport
                    title={"Import Data Grafik"}
                    icon={<ImportIcon />}
                    action={() => setViewForm(true)}
                  />
                  <hr />
                  {/* form input chart */}
                  <div className="d-flex flex-row col-12">
                    <div className="col-12">
                      <LineChart />
                    </div>
                  </div>
                  <div className="col-12">
                    <BarChart />
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <DoughnutChart />
                    </div>
                    <div className="col-6">
                      <RadarCharts />
                    </div>
                  </div>
                  <div className="d-flex flex-row col-12">
                    <div className="col-6">
                      <PieChart />
                    </div>
                    <div className="col-6">
                      <PolarAreaCharts />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

export default Economy;
