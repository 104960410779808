import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import LogoPemko from "../../assets/images/pemko.png";
import LoginIcon from "@mui/icons-material/Login";
import "./header.css";
import Button from "components/Button/Button";

export default function Header() {
  return (
    <div>
      <Navbar variant="dark" style={{ height: "15vh" }}>
        <Container>
          <Navbar.Brand href="/">
            <div className="d-flex flex-row align-items-center">
              <img src={LogoPemko} alt="" />
              <div className="d-flex flex-column mx-3">
                <p
                  style={{
                    color: "black",
                    fontSize: 24,
                    fontWeight: 700,
                    margin: 0
                  }}>
                  Pemko
                </p>
                <p
                  style={{
                    color: "black",
                    fontSize: 24,
                    fontWeight: 700,
                    margin: 0
                  }}>
                  Kota Medan
                </p>
              </div>
            </div>
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="login-option">
              <Button title={"Login"} icon={<LoginIcon />} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}
