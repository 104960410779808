import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Card.css";
import PersonIcon from "@mui/icons-material/Person";

const Card = () => {
  return (
    <div className="container">
      <div className="Cards">
        <Row>
          <Col sm>
            <div className="colom">
              <div className="left-border green"></div>
              <div className="right">
                <p className="titleCard hijau">
                  <PersonIcon className="icon" /> Penduduk
                </p>
                <p className="titleCard2">1.101.000 Jiwa</p>
              </div>
            </div>
          </Col>
          <Col sm>
            <div className="colom">
              <div className="left-border yellow"></div>
              <div className="right">
                <p className="titleCard kuning">
                  <PersonIcon className="icon" /> Luas Wilayah
                </p>
                <p className="titleCard2">
                  265,1 km<small>2</small>
                </p>
              </div>
            </div>
          </Col>
          <Col sm>
            <div className="colom">
              <div className="left-border red"></div>
              <div className="right">
                <p className="titleCard merah">
                  <PersonIcon className="icon" /> Sekolah
                </p>
                <p className="titleCard2">
                  265,1 km<small>2</small>
                </p>
              </div>
            </div>
          </Col>
          <Col sm>
            <div className="colom">
              <div className="left-border blue"></div>
              <div className="right">
                <p className="titleCard biru">
                  <PersonIcon className="icon" /> Request
                </p>
                <p className="titleCard2">5</p>
              </div>
            </div>
          </Col>
          <Col sm>
            <div className="colom">
              <div className="left-border green2"></div>
              <div className="right">
                <p className="titleCard hijau2">
                  <PersonIcon className="icon" /> Ekonomi
                </p>
                <p className="titleCard2">56,65</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Card;
