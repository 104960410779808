import LandingPage from "pages/LandingPages/LandingPage";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginOption from "pages/LandingPages/LoginPage/LoginOption";
import LoginDinas from "pages/LandingPages/LoginPage/LoginDinas/LoginDinas";
import LoginAdmin from "pages/LandingPages/LoginPage/LoginAdmin/LoginAdmin";
import Dashboard from "pages/Dashboard/Dashboard";
import Economy from "pages/Dashboard/pages/Economy";
import Social from "pages/Dashboard/pages/Social";
import Politic from "pages/Dashboard/pages/Politic";
import RequestAccount from "pages/Dashboard/pages/RequestAccount";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/login-option" element={<LoginOption />}></Route>
        <Route path="/login-dinas" element={<LoginDinas />}></Route>
        <Route path="/login-admin" element={<LoginAdmin />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/dashboard/economy" element={<Economy />}></Route>
        <Route path="/dashboard/social" element={<Social />}></Route>
        <Route path="/dashboard/politic" element={<Politic />}></Route>
        <Route path="/request" element={<RequestAccount />}></Route>
      </Routes>
    </div>
  );
}

export default App;
