import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import LogoPemko from "assets/images/pemko.png";
import "./header.css";
import Button from "../Button/Button";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";

export default function Header() {
  return (
    <div className="header">
      <Navbar variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <div className="d-flex flex-row align-items-center">
              <img src={LogoPemko} alt="" />
              <div className="d-flex flex-column mx-3">
                <p className="judul">SATU DATA KOTA MEDAN</p>
              </div>
            </div>
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="login-option">
              <Button title={"Login"} icon={<LoginRoundedIcon />} />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}
