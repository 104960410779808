import React from "react";
import "./loginDinas.css";
import SearchIcon from "@mui/icons-material/Search";

import LogoPemko from "assets/images/pemko.png";
import { Form, InputGroup } from "react-bootstrap";
import Button from "components/Button/Button";
import ButtonBordered from "components/ButtonBordered/ButtonBordered";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const LoginDinas = () => {
  return (
    <div
      className="d-flex flex-row"
      style={{ backgroundColor: "#D3E7D8", height: "100vh" }}>
      <div className="col-6 d-flex flex-column pt-5 align-items-center justify-content-center">
        <h1
          className="text-left"
          style={{
            fontWeight: 700,
            fontSize: 40,
            marginLeft: 50,
            color: "#25883B"
          }}>
          Halo Lae... <br /> Selamat Datang Kembali di
          <p className="title-login">SmartCity</p>
        </h1>
        <img
          style={{ width: 460, height: 370 }}
          src={require("assets/images/img-login.png")}
          alt="login"
        />
      </div>
      <div className="col-6 d-flex justify-content-center flex-column">
        <div className="d-flex flex-row align-items-center">
          <img src={LogoPemko} alt="logo" />
          <div className="d-flex flex-column mx-3">
            <p
              style={{
                color: "black",
                fontSize: 24,
                fontWeight: 700,
                margin: 0
              }}>
              Pemko
            </p>
            <p
              style={{
                color: "black",
                fontSize: 24,
                fontWeight: 700,
                margin: 0
              }}>
              Kota Medan
            </p>
          </div>
        </div>
        <div className="text-center my-3">
          <p
            style={{
              color: "#444444",
              fontSize: 24,
              fontWeight: 700,
              margin: 0
            }}>
            Login
          </p>
        </div>
        <div style={{ padding: "0 100px" }}>
          <div className="mb-4">
            <Form.Label>Username</Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="bg-transparent"
                style={{
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderRight: 0
                }}>
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderLeft: 0
                }}
                className="py-3"
                placeholder="Masukkan Username"
              />
            </InputGroup>
          </div>
          <div className="mb-4">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="bg-transparent"
                style={{
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderRight: 0
                }}>
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                type="password"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderLeft: 0
                }}
                className="py-3"
                placeholder="Masukkan Password"
              />
            </InputGroup>
          </div>
          <div className="mb-4">
            <Form.Label>Kode Akun Dinas</Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="bg-transparent"
                style={{
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderRight: 0
                }}>
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid rgba(68, 68, 68, 0.5)",
                  borderLeft: 0
                }}
                className="py-3"
                placeholder="Masukkan Kode"
              />
            </InputGroup>
          </div>
          <div className="d-flex flex-row justify-content-around">
            <a href="/dashboard">
              <Button title={"Login"} />
            </a>
            <a href="/login-option">
              <ButtonBordered title={"Kembali"} icon={<ArrowBackIcon />} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDinas;
