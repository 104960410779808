import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "./Menubar.css";
import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Icons from "@mui/icons-material/AccountTree";
import User from "@mui/icons-material/SwitchAccount";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DashboardIcon from "@mui/icons-material/Dashboard";

function Menubar() {
  return (
    <Container className="navv">
      <Nav className="justify-content-center" activeKey="/home">
        <Nav.Item>
          <Nav.Link href="/dashboard">
            <p className="link">
              {" "}
              <DashboardIcon /> Dashboard
            </p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/request">
            <p className="link">
              <Icons /> Request
            </p>
          </Nav.Link>
        </Nav.Item>
        <Dropdown as={NavItem} className="link">
          <Dropdown.Toggle as={NavLink} style={{ color: "#fff" }}>
            <QueryStatsIcon /> Ekonomi
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="/dashboard/economy">Pangan</Dropdown.Item>
            <Dropdown.Item href="/dashboard/economy">Pendapatan</Dropdown.Item>
            <Dropdown.Item href="/dashboard/economy">E-Commerce</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Nav.Item>
          <Nav.Link href="/dashboard/politic">
            <p className="link">
              <ApartmentIcon /> Politik
            </p>
          </Nav.Link>
        </Nav.Item>
        <Dropdown as={NavItem} className="link">
          <Dropdown.Toggle as={NavLink} style={{ color: "#fff" }}>
            <RecentActorsIcon /> Sosial
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="/dashboard/social">Kesehatan</Dropdown.Item>
            <Dropdown.Item href="/dashboard/social">Pendidikan</Dropdown.Item>
            <Dropdown.Item href="/dashboard/social">
              Kesejahteraan
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Nav.Item>
          <Nav.Link href="/dashboard">
            <p className="link">
              <User /> Akun User
            </p>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Container>
  );
}

export default Menubar;
