import Button from "components/Button/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
export default function LoginOption() {
  return (
    <div className="container-fluid" style={{ minHeight: "100vh" }}>
      <div style={{ height: "20vh" }}>
        <img
          src={require("assets/images/icons/circle-t.png")}
          alt=""
          className="col-1"
        />
      </div>
      <div
        style={{
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}>
        <div className="row text-center">
          <h1 style={{ fontWeight: 700 }}>Silahkan Melakukan Login</h1>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column col-8">
            <div className="d-flex flex-row justify-content-center">
              <div
                className="d-flex flex-column align-items-center p-5 mt-3"
                style={{
                  borderWidth: 4,
                  borderRadius: 10,
                  borderStyle: "dashed",
                  borderColor: "#9EDFAC"
                }}>
                <ManageAccountsIcon
                  fontSize="large"
                  style={{ width: 100, height: 100 }}
                />
                <a href="/login-admin">
                  <Button title={"Login Sebagai Admin"} />
                </a>
              </div>
              <div
                className="d-flex flex-column align-items-center p-5 mt-3"
                style={{
                  borderWidth: 4,
                  borderLeft: 0,
                  borderRadius: 10,
                  borderStyle: "dashed",
                  borderColor: "#9EDFAC"
                }}>
                <PersonIcon
                  fontSize="large"
                  style={{ width: 100, height: 100 }}
                />
                <a href="/login-dinas">
                  <Button title={"Login Sebagai Dinas"} />
                </a>
              </div>
            </div>
            <a href="/" style={{ textDecoration: "none" }}>
              <div className="d-flex flex-row p-2">
                <ArrowBackIcon style={{ color: "#25883B" }} />
                <p style={{ color: "#25883B", fontWeight: 600 }}>Kembali</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "20vh",
          justifyContent: "flex-end",
          display: "flex"
        }}>
        <img
          src={require("assets/images/icons/circle-t.png")}
          alt="logo"
          className="col-1"
          style={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
}
